import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import BreakpointsProvider from '../../../hooks/BreakpointsProvider';
import type { AppWidgetProps } from '../../../types/common';
import CssVars from '../../PackagePicker/CssVars';
import CheckoutWidget from './CheckoutWidget';

const BREAKPOINTS = {
  isNarrow: (width: number) => width > 0 && width <= 750,
  isExtraNarrow: (width: number) => width > 0 && width <= 450,
};

export default function (props: AppWidgetProps) {
  const { isMobile } = useEnvironment();
  const width = props.host.dimensions.width ?? (isMobile ? 320 : 980);
  return (
    <BreakpointsProvider breakpoints={BREAKPOINTS} defaultWidth={width}>
      <CheckoutWidget {...props} />
      <CssVars id={props.host.id} style={props.host.style} />
    </BreakpointsProvider>
  );
}
